/* .projectList{
    width: 85%;
    margin: 2rem auto;
    border: 7px solid green;
    

}
.projectListDiv{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    border: 5px solid gold;
    
} */
.projects {
  text-align: center;
}

@media (max-width: 768px) {
  /* .projectItems {
    margin-bottom: 15rem;
  } */
}

/* @media (max-width: 768px) {
    .projectList{
        width: 70%;
    }
    .projectListDiv{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 426px) {
    .projectList{
        width: 95%;
    }
} */
/* 
.projects{
    text-align: center;
    color: #33a06f;
}

.underline{
    width: 5rem;
    height: 0.2rem;
    background-color: white;
}


@media (max-width: 426px) {
    html {
      font-size: 10px;
    }
    
    .projectList{
        display: block;
    }

    .projectListDiv{
        width: 90%;
        margin: auto;

    }
}

@media (max-width: 320px) {
  html{
      font-size: 8px;
  }
} */
