/* .projectCard{
    padding:3rem;
    height: 30rem;
    border-radius: 10px;
   
}
.projectCard a{
    text-decoration: none;
    color: #33a06f;
}
.projectCard img{
    width: 28rem;
    height: 15rem;
} */

.tools {
  padding-top: 1rem;
  width: 30rem;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.tools p {
  font-size: 12px;
  color: #33a06f;
  border: 1px solid #33a06f;
  padding: 2px;
}

/* .cardImage {
  opacity: 5;
} */
.gitHub p {
  text-decoration: underline;
}

.projectCardinner {
  margin-top: 4rem;
  color: #33a06f;
  /* border: 2px solid red; */
  height: 35rem;
}
.text {
  color: #33a06f;
  padding: 1rem;
  /* padding-left: 1rem; */
  /* border: 2px solid darkblue; */
}
.text a {
  text-decoration: none;
  color: #33a06f;
  /* padding-left: 5rem; */
}
.text h6 {
  font-weight: lighter;
}
@media (max-width: 769px) {
  .projectCardinner {
    height: 50rem;
  }
}
@media (max-width: 763px) {
  .projectCardinner {
    height: 45rem;
  }
}
@media (max-width: 476px) {
  .projectCardinner {
    height: 35rem;
  }
}
/* @media (max-width: 769px) {
    .projectCard{
        width: 50rem;
        height: 45rem;
    }
    .projectCard img{
        width: 47rem;
        height: 23rem;
    }
    .projectCard p{
        font-size: 13px;
    }
    .tools p{
        font-size: 12px;
        color: #33a06f;
        border: 1px solid #33a06f;
        padding: 2px; 
    }
}
@media (max-width: 426px) {
    .projectCard{
        width: 37rem;
        height: 50rem;
    }
    .projectCard img{
        width: 34rem;
    }
}
@media (max-width: 375px) {
    .projectCard{
        width: 32rem;
    }
    .projectCard img{
        width: 30rem;
    }
}
@media (max-width: 320px) {
    .projectCard{
        width: 27rem;
    }
    .projectCard img{
        width: 23rem;
        height: 20rem;
    }
} */
/* 
@media (max-width: 426px) {
    .projectCardContainer{
        width: 90%;
        margin: auto;
    }

}

@media (max-width: 375px) {
.projectCard{
   width: 26rem; 
}
.projectCard img{
    width: 24rem;
}
   
}    


@media (max-width: 321px) {
.projectCardContainer{
    padding-right: 10rem;
}
.projectCard{
    width: 20rem; 
 }
 .projectCard img{
    width: 19rem;
}
} */
