.linksDiv a {
  /* width: 1rem; */
  text-decoration: none;
  color: #33a06f;
  margin: 10px;
}
footer {
  color: #33a06f;
  background-color: #181818;
}
.footerDiv {
  margin-bottom: 2rem;
}
