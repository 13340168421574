.contactContainer {
  width: 50%;
  margin: auto;
  padding: 3rem;
}

.contactForm h1,
.contactForm p {
  text-align: center;
  color: #33a06f;
}
/* .underline{
    width: 5rem;
    height: 0.2rem;
    background-color: white;
    text-align: center;
} */
form {
  padding: 1rem;
}

form input,
textarea {
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  /* padding: .5rem .8rem .5rem .8rem; */
  /* margin: .9vw 0 ; */
  /* border:0; */
  /* border-radius: 5px; */
  font-size: 20px;
  border: none;
  border-radius: 5px;
}
.send {
  background-color: #020a13;
  color: #33a06f;
  border: 2px solid #33a06f;
  padding: 10px;
  width: 50%;
  margin: auto;
}
.send:hover {
  background-color: #33a06f;
  color: white;
}
.modal {
  background-color: #020a13;
}
.buttonText {
  color: #33a06f;
  text-align: center;
}

@media (max-width: 769px) {
  html {
    font-size: 10px;
  }
  .contactContainer {
    width: 70%;
  }

  .contactForm p {
    font-size: 16px;
  }
}

@media (max-width: 426px) {
  .contactContainer {
    width: 95%;
  }

  form input {
    height: 3rem;
    /* font-size: 2px; */
  }
  input[type="text"] {
    font-size: 9px;
  }
  input[type="email"] {
    font-size: 9px;
  }

  textarea {
    font-size: 9px;
  }

  ::placeholder {
    font-size: 0.8rem;
  }
  .send {
    font-size: 12px;
    padding-bottom: 2.5rem;
  }
  .send input {
    height: 2.5rem;
    width: 60%;
    /* font-size: 1rem; */
    padding: 2rem;
  }
}

@media (max-width: 376px) {
  .contactContainer {
    width: 100%;
  }
}
