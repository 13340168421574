@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&family=Inconsolata:wght@500;600;700;900&family=Inter:wght@400;500;700&family=Josefin+Sans:wght@200;700&family=Manrope:wght@800&family=Montserrat:wght@100&family=Nunito+Sans:ital,wght@0,300;0,400;0,600;1,800&family=Open+Sans&family=Poppins:wght@500;700&family=Saira+Condensed:wght@300&display=swap");
body {
  margin: 0;
  font-family: "Exo 2", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
