.aboutContainer {
  width: 90%;
  margin: 6rem auto;
}
.aboutContainerDiv {
  /* display: flex; */
  display: flex;
  gap: 15rem;
}
.aboutText {
  width: 35%;
  line-height: 1.5rem;
}
.aboutText h1 {
  color: #33a06f;
}
.magic {
  text-decoration: none;
  color: #33a06f;
  font-size: 1.2rem;
  font-weight: 500;
}
.resume {
  text-decoration: none;
  color: #33a06f;
  font-weight: 500;
}
.resume:hover,
.magic:hover {
  text-decoration: underline;
  color: gold;
}
.underline {
  width: 15rem;
  height: 0.2rem;
  background-color: white;
  /* text-align: center; */
}
.messi {
  color: gold;
}

@media (max-width: 768px) {
  html {
    font-size: 10px;
  }
  .aboutContainerDiv {
    gap: 5rem;
  }
  .aboutContainer img {
    width: 35rem;
  }

  .aboutText p {
    font-size: 1.2rem;
  }
}

@media (max-width: 426px) {
  .aboutContainer img {
    width: 20rem;
  }

  /* .aboutContainer{
    
  } */

  .aboutContainerDiv {
    display: block;
    width: 100%;
    margin: auto;
  }
  .aboutContainer img {
    display: none;
  }
  .aboutText {
    width: 37rem;
    line-height: 2rem;
    padding-right: 2rem;
  }
  .aboutText p {
    font-size: 1.4rem;
    /* text-align: center; */
  }
  .underline {
    display: none;
  }
}

@media (max-width: 376px) {
  .aboutText {
    width: 33rem;
    line-height: 2rem;
  }
  .aboutText p {
    font-size: 1.3rem;
    /* text-align: center; */
  }
}

@media (max-width: 321px) {
  .aboutText {
    width: 30rem;
    line-height: 2rem;
  }
}
